import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfigDev = {
	apiKey: "AIzaSyBu10LWxnDTnP6gqduB6B7T3YYqGNw5TsE",
	authDomain: "premiumandpremiumdev.firebaseapp.com",
	projectId: "premiumandpremiumdev",
	storageBucket: "premiumandpremiumdev.appspot.com",
	messagingSenderId: "761203799311",
	appId: "1:761203799311:web:dbf2c1b43742136541ba7a",
	measurementId: "G-KF7CTGNK3T"
};

const firebaseConfig = {
	apiKey: "AIzaSyBDV9xis_f87skyWCdQOkb470WnLQ4o0OQ",
	authDomain: "premiumandpremium.firebaseapp.com",
	databaseURL: "https://premiumandpremium.firebaseio.com",
	projectId: "premiumandpremium",
	storageBucket: "gs://premiumandpremium.appspot.com",
	messagingSenderId: "717119067410",
	appId: "1:717119067410:web:c9dabb0b80cda581c9cfcb",
	measurementId: "G-EZ98THXPJC"
  };

  firebase.initializeApp(firebaseConfig);

  const auth = firebase.auth()

  const db = firebase.firestore()

  const storage = firebase.storage()

  const firestore = firebase.firestore;


  export { auth, firebase, db, storage, firestore }